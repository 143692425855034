<template>
  <div class="order-basket">
    <b-row>
      <b-col :[$root.md.key]="12" tag="article">
        <div class="basket-items">
          <div class="items-header">
            <div class="area-description">{{ $t('components.order.basket.header.description') }}</div>
            <div class="area-quantity">{{ $t('components.order.basket.header.quantity') }}</div>
            <div class="area-price">{{ $t('components.order.basket.header.price') }}</div>
            <div class="area-totalprice">{{ $t('components.order.basket.header.totalprice') }}</div>
            <div class="area-services" v-if="canCancel"></div>
          </div>
          <div class="items-main">
            <basket-item v-for="item in order.executedBasket.items" :key="item.basketItemId" @cancelPosition="cancelPosition" @reactivatePosition="reactivatePosition" @changeQuantity="changeQuantity" :order-state="order.orderState" :item="item" :error-message="getVoigtMessage(item.item.pharmaCode)">
              <div class="row" v-if="showReturns && item.item.isReturnable">
                <div class="col-md-auto col-12">
                  <b-form-checkbox v-model="selectedProductsToReturn[item.item.id].selected" :disabled="selectedProductsToReturn[item.item.id].disabled">
                    <small>{{ $t("components.order.basket.returnproduct") }}</small>
                  </b-form-checkbox>
                </div>
                <div class="col-8 col-md-auto">
                  <b-form-group>
                    <b-form-select :disabled="selectedProductsToReturn[item.item.id].disabled || !selectedProductsToReturn[item.item.id].selected" :state="!$v.selectedProductsToReturn[item.item.id].reason.$error" v-model="selectedProductsToReturn[item.item.id].reason" :options="getReturnReasons()"></b-form-select>
                    <b-form-invalid-feedback :state="!$v.selectedProductsToReturn[item.item.id].reason.$error">{{ $t('components.controls.feedbacks.invalid.required') }}</b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="col-4 col-md-auto">
                  <b-form-select :disabled="selectedProductsToReturn[item.item.id].disabled || !selectedProductsToReturn[item.item.id].selected" v-model="selectedProductsToReturn[item.item.id].quantity" :options="Array.from({ length: item.quantity }, (_, i) => i + 1)"></b-form-select>
                </div>
              </div>
            </basket-item>
            <b-button v-if="showReturns" @click="addReturn" :disabled="Object.keys(selectedProductsToReturn).filter(itm => selectedProductsToReturn[itm].selected && !selectedProductsToReturn[itm].disabled).length <= 0" variant="primary">Retoure erfassen</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import orderReturnsService from '@/$plugins/services/order-returns-service'
import BasketItem from '@/components/order/BasketItem'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'OrderBasket',
  components: {
    BasketItem
  },
  props: {
    order: {
      type: Object,
      default: null
    },
    showReturns: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      selectedProductsToReturn: {}
    }
  },
  validations () {
    return {
      selectedProductsToReturn: Object.keys(this.selectedProductsToReturn)
        .reduce((controls, controlKey) => Object.assign(controls, {
          [controlKey]: {
            reason: {
              required: requiredIf(v => {
                return this.selectedProductsToReturn[controlKey].selected
              })
            }
          }
        }), {})
    }
  },
  async mounted () {
    this.selectedProductsToReturn = this.order.executedBasket.items.filter((itm) => itm.item.isReturnable)
      ?.reduce((accumulator, itm) => {
        return { ...accumulator, [itm.item.id]: { selected: false, quantity: 1, reason: null, disabled: false } }
      }, {}) ?? {}

    const result = await orderReturnsService.getOrderReturnsByOrderId(this.order.orderId)
    if (result) {
      result.products.forEach((itm) => {
        if (this.selectedProductsToReturn[itm.item.id]) {
          this.$set(this.selectedProductsToReturn, itm.item.id, { selected: true, quantity: itm.quantity, reason: itm.reason })
        }
      })
      Object.keys(this.selectedProductsToReturn).forEach((key) => {
        this.selectedProductsToReturn[key].disabled = true
      })
    }
  },
  computed: {
    canCancel () {
      return this.order.orderState === 'OrderFailed'
    }
  },
  methods: {
    getVoigtMessage (pharmacode) {
      return this.order.voigtProductOrderMessages ? this.order.voigtProductOrderMessages[pharmacode] ?? '' : ''
    },
    cancelPosition (item) {
      this.$emit('cancelPosition', item)
    },
    reactivatePosition (item) {
      this.$emit('reactivatePosition', item)
    },
    changeQuantity (param) {
      this.$emit('changeQuantity', param)
    },
    getReturnReasons () {
      return [
        { value: null, text: this.$t('components.order.basket.returnreason.select') },
        { value: 'WrongDelivery', text: this.$t('components.order.basket.returnreason.wrongdelivery') },
        { value: 'DoesNotLike', text: this.$t('components.order.basket.returnreason.doesnotlike') },
        { value: 'DoesNotFit', text: this.$t('components.order.basket.returnreason.doesnotfit') },
        { value: 'Defective', text: this.$t('components.order.basket.returnreason.defective') }
      ]
    },
    async addReturn () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const products = Object.keys(this.selectedProductsToReturn)
          .filter((key) => this.selectedProductsToReturn[key].selected)
          .map((key) => ({
            productId: key,
            quantity: this.selectedProductsToReturn[key].quantity,
            reason: this.selectedProductsToReturn[key].reason
          }))
        await orderReturnsService.addOrderReturnsByOrderId({
          orderId: this.order.orderId,
          products: products
        })
        this.$router.go()
      }
    }
  }
}
</script>

<style lang="scss">
// mobile

$orderbasket-emptymessage-content-text-align: inherit !default;

$orderbasket-items-grid-column-gap: $grid-gutter-width * 0.5 !default;
$orderbasket-items-grid-row-gap: $spacer * 0.4 !default;
$orderbasket-items-grid-columns: [start] auto 4fr auto auto auto [end] !default;
$orderbasket-items-grid-rows: min-content auto min-content !default;
$orderbasket-items-grid-area-gap-y: $spacer !default;

$orderbasket-items-header-font-size: $font-size-sm !default;
$orderbasket-items-header-color: $dark !default;
$orderbasket-items-header-line-height: 1.1 !default;

$orderbasket-items-footer-actions-gap-y: $spacer * 0.5 !default;
$orderbasket-items-footer-actions-gap-x: $spacer * 2 !default;

.order-basket {
  .basket-emptymessage {
    margin-top: 0 !important;

    .emptymessage-content {
      text-align: $orderbasket-emptymessage-content-text-align;
    }
  }

  .basket-items {
    display: grid;
    column-gap: $orderbasket-items-grid-column-gap;
    row-gap: $orderbasket-items-grid-row-gap;
    grid-template-columns: $orderbasket-items-grid-columns;
    grid-template-rows: $orderbasket-items-grid-rows;

    [class*="area-"] {
      grid-row: auto;
    }

    .items-header {
      display: contents;
      font-size: $orderbasket-items-header-font-size;
      color: $orderbasket-items-header-color;
      line-height: $orderbasket-items-header-line-height;

      .area-description {
        grid-column: start / 3;
      }
    }

    .area-image,
    .area-description,
    .area-configuration,
    .area-quantity {
      margin-bottom: 0;
    }

    .area-separator {
      grid-row-gap: 0;
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-image {
      grid-column: start;
      grid-row: auto / span 2;
      justify-self: stretch;
    }

    .area-description {
      grid-column: 2;
    }

    .area-configuration {
      grid-column: 2 / span 3;
      align-self: center;
    }

    .area-quantity {
      grid-column: 3;
      justify-self: center;
    }

    .area-price {
      grid-column: 4;
      justify-self: flex-end;
    }

    .area-totalprice {
      grid-column: 5;
      justify-self: flex-end;
    }

    .area-services {
      grid-column: start / end;
      justify-self: flex-end;
    }

    .items-main {
      display: contents;
    }

    .items-footer {
      display: contents;

      .area-basketactions-start {
        grid-column: start / 4;
      }

      .area-basketactions-end {
        grid-column: 4 / end;
        justify-self: flex-end;
        margin-top: 0;
      }

      .footer-actions {
        display: flex;
        margin: ($orderbasket-items-footer-actions-gap-y * -0.5) ($orderbasket-items-footer-actions-gap-x * -0.5);

        a {
          display: block;
          padding: ($orderbasket-items-footer-actions-gap-y * 0.5) ($orderbasket-items-footer-actions-gap-x * 0.5);
        }
      }
    }
  }
}
</style>
