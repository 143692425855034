<template functional>
  <div :id="data.id" :class="['summary-separator', data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <hr/>
  </div>
</template>

<script>
export default {
  name: 'SummarySeparator'
}
</script>

<style lang="scss">
$summary-separator-margin-y: $spacer * 0.5 !default;

.summary-separator {
  hr {
    margin-top: $summary-separator-margin-y;
    margin-bottom: $summary-separator-margin-y;
  }
}
</style>
