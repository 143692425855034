<template>
  <div class="order-detail">
    <h2 class="sr-only">{{ $t('views.orders.detail.title') }}</h2>

    <loader v-if="is.loading" />

    <template v-else>
      <div class="order-header">
        <dl>
          <div>
            <dt>{{ $t('components.order.header.date.label') }}</dt>
            <dd>{{ moment(entity.orderReceivedDate ? entity.orderReceivedDate : entity.created).format('DD.MM.YYYY HH:mm:ss') }}</dd>
          </div>
          <div>
            <dt>{{ definition.$properties.orderId.translations[$i18n.locale].name }}</dt>
            <dd>{{ entity.orderId }}</dd>
          </div>

          <div>
            <dt>{{ $t('components.order.header.deliverytype.label') }}</dt>
            <dd v-if="entity.configuration.deliveryType">{{ $t(`components.order.configuration.delivery.type.${entity.configuration.deliveryType.toLowerCase()}`) }}</dd>
          </div>

          <div>
            <dt>{{ $t('components.order.header.paymenttype.label') }}</dt>
            <dd>{{ entity.configuration.payrexxPaymentType }}</dd>
          </div>

          <div>
            <dt><span v-html="entity.executedBasket.priceDetail.totalPriceLabel[$i18n.locale]"></span></dt>
            <dd>{{ $n(entity.executedBasket.priceDetail.totalPrice, 'currency') }}</dd>
          </div>

          <div>
            <dt>{{ definition.$properties.orderState.translations[$i18n.locale].name }}</dt>
            <dd>
              <b-badge :variant="orderStateBadgeVariants[entity.orderState]">{{ valueFormatter(definition, 'orderState', entity.orderState).value }}</b-badge>
            </dd>
          </div>
        </dl>
      </div>

      <div class="order-actions mt-3 text-right">
        <p>
          <b-button-group>
            <b-button variant="info" @click="openModal('execute')"><b-icon icon="orders" /> {{ $t('components.order.orderactions.reexecuteorder') }}</b-button>
            <b-button v-if="entity.orderState === 'Closed' && entity.executedBasket.items.filter((itm) => itm.item.isReturnable).length && !returns.show" variant="info" @click="addReturns"><b-icon icon="b-arrow-clockwise" /> {{ $t('components.order.orderactions.addreturnorder') }}</b-button>
            <b-button v-if="entity.orderState === 'Closed' && returns.show" variant="danger" @click="hideReturns"><b-icon icon="b-arrow-clockwise" /> {{ $t('components.order.orderactions.canceladdreturnorder') }}</b-button>
            <b-dropdown v-if="['Processed', 'Closed', 'PartialDelivery', 'OrderFailed', 'PartiallyCanceled', 'Canceled'].includes(entity.orderState)" variant="info" right :text="$t('components.order.orderactions.documents')">
              <b-dropdown-item @click="downloadDeliveryNote()">{{ $t('components.order.orderactions.document.deliverynote') }}</b-dropdown-item>
              <b-dropdown-item @click="downloadPostZebra()">{{ $t('components.order.orderactions.document.zebrafile') }}</b-dropdown-item>
              <b-dropdown-item @click="downloadInvoice()">{{ $t('components.order.orderactions.document.invoice') }}</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </p>
        <p v-if="entity.orderState === 'OrderFailed'">
          <b-button variant="danger" @click="changeToCanceledState()"><b-icon icon="abort" /> {{ $t('components.order.orderactions.cancelorder') }}</b-button>
        </p>
        <p v-if="(entity.orderState === 'OrderFailed' || entity.orderState === 'PartialDelivery') && isPartiallyCanceled">
          <b-button variant="warning" @click="changeToPartiallyCanceledState()"><b-icon icon="b-receipt" /> {{ $t('components.order.orderactions.partiallycanceled') }}</b-button>
        </p>
      </div>
      <b-alert v-if="entity.orderState !== 'Ordered' && entity.orderState !== 'Processed' && entity.orderState !== 'Closed' && entity.orderState !== 'OrderFailed' && entity.orderState !== 'PartialDelivery' && entity.orderState !== 'Canceled' && entity.orderState !== 'PartiallyCanceled'" variant="warning" class="mt-3" :show="true">
        {{ $t('components.order.notprocessedordermessage') }}
      </b-alert>
      <b-alert v-if="entity.configuration.orderType === 'Quote'" variant="info" class="mt-3" :show="true">
        {{ $t('components.order.quotehint', { quoteValidUntil: $d(new Date(entity.configuration.quoteValidUntil)) }) }}
      </b-alert>
      <order-basket :order="entity" :show-returns="returns.show" @cancelPosition="cancelPosition" @reactivatePosition="reactivatePosition" @changeQuantity="changeQuantity" />

      <order-configuration :summary="summary" :configuration="entity.configuration" :order="entity" />
    </template>

    <b-modal :id="executeOrderModal.id" v-model="executeOrderModal.show" size="md" centered scrollable no-fade header-bg-variant="warning" header-text-variant="black" title-tag="h4" title-class="align-self-center" hide-footer>
      <template #modal-header-close><b-icon icon="close" scale="0.6" /></template>
      <template #modal-title>Bestellung erneut ausführen?</template>

      <template v-if="executeOrderModal.show">
        <div class="execute-order-modal">
          <template v-if="reexecuteOrderResult === null">
            <loader v-if="reexecutionLoading" />

            <p>Die Bestellung wird erneut ausgelöst und an Voigt gesendet. Bitte nachfolgend die Optionen für die Bestellungseinlieferung wählen.</p>
            <ul class="unstyled">
              <li>
                <b-checkbox :disabled="disabledExecuteOrderConfigurations.checkPayment" v-model="executeOrderConfiguration.checkPayment" :class="{ 'text-danger': !executeOrderConfiguration.checkPayment }">Bezahlung prüfen</b-checkbox>
                <div class="alert alert-danger mt-2" v-if="!executeOrderConfiguration.checkPayment"><strong>Achtung:</strong> es muss manuell geprüft werden ob diese Bestellung bezahlt wurde!</div>
              </li>
              <li>
                <b-checkbox :disabled="disabledExecuteOrderConfigurations.processCouponsAndVouchers" v-model="executeOrderConfiguration.processCouponsAndVouchers" :class="{ 'text-danger': !executeOrderConfiguration.processCouponsAndVouchers }">Voucher und Geschenkgutscheine verarbeiten</b-checkbox>
                <div class="alert alert-danger mt-2" v-if="!executeOrderConfiguration.processCouponsAndVouchers"><strong>Achtung:</strong> die Vouchers und Geschenkgutscheine müssen manuell geprüft und abgebucht werden!</div>
              </li>
              <li><b-checkbox :disabled="disabledExecuteOrderConfigurations.createOrUpdateUsers" v-model="executeOrderConfiguration.createOrUpdateUsers">Benutzer erstellen / aktualisieren</b-checkbox></li>
              <li><b-checkbox :disabled="disabledExecuteOrderConfigurations.sendEmails" v-model="executeOrderConfiguration.sendEmails">Bestellbestätigung-Emails senden</b-checkbox></li>
              <li><b-checkbox :disabled="disabledExecuteOrderConfigurations.sendOrderToVoigt" v-model="executeOrderConfiguration.sendOrderToVoigt">Bestellung an Voigt einliefern</b-checkbox></li>
            </ul>
          </template>
          <template v-else>
            <template v-if="reexecuteOrderResult.success">
              <p>Die Bestellung wurde erfolgreich ausgelöst.</p>
            </template>
            <template v-else>
              <div class="alert alert-danger">
                <p>Beim Auslösen der Bestellung sind Fehler aufgetreten:</p>
                <ul>
                  <li v-for="error in reexecuteOrderResult.result.errors" :key="error">{{ error[$i18n.locale] }}</li>
                </ul>
              </div>
            </template>
          </template>
        </div>

        <div class="d-flex justify-content-end mt-3">
          <div class="btn-list">
            <template v-if="!reexecutionLoading && reexecuteOrderResult === null">
              <b-button variant="light" size="sm" @click="closeModal">Abbrechen</b-button>
              <b-button variant="warning" size="sm" @click="executeOrder"><b-icon icon="orders" /> Bestellung erneut auslösen</b-button>
            </template>
            <template v-else>
              <b-button variant="light" size="sm" @click="closeModal">Schliessen</b-button>
            </template>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, ORDER_STATES, ORDER_STATES_BADGES_VARIANTS } from '@/constants'

import { valueFormatter } from '@/assets/js/helper/entity'
import moment from 'moment'

import orderDocumentsService from '@/$plugins/services/order-documents-service'
import Loader from '@/components/Loader'
import MixinEntityBase from '@/components/mixins/EntityBase'
import OrderBasket from '@/components/order/OrderBasket'
import OrderConfiguration from '@/components/order/OrderConfiguration'

export default {
  name: 'Orders.Detail',
  mixins: [MixinEntityBase],
  components: {
    Loader,
    OrderBasket,
    OrderConfiguration
  },
  data () {
    return {
      orderStateBadgeVariants: Object.keys(ORDER_STATES)
        .reduce((variants, sKey) => Object.assign(variants, { [ORDER_STATES[sKey]]: ORDER_STATES_BADGES_VARIANTS[sKey] }), {}),
      executeOrderModal: {
        id: `${this.$options.name}_ExecuteOrderModal`,
        show: false
      },
      reexecutionLoading: false,
      reexecuteOrderResult: null,
      disabledExecuteOrderConfigurations: {
        checkPayment: false,
        processCouponsAndVouchers: false,
        createOrUpdateUsers: false,
        sendEmails: false,
        sendOrderToVoigt: false
      },
      executeOrderConfiguration: {
        checkPayment: true,
        processCouponsAndVouchers: true,
        createOrUpdateUsers: true,
        sendEmails: true,
        sendOrderToVoigt: true
      },
      returns: {
        show: false
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eParent.key}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id) || {}
    },
    summary () {
      const priceDetails = this.entity.executedBasket.priceDetail || {}

      return []
        .concat(priceDetails.priceLines ?? [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel ?? {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$i18n.locale]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    },
    isPartiallyCanceled () {
      return this.entity?.executedBasket.items.filter(itm => itm.isCanceled || itm.quantityOverride).length > 0
    }
  },
  methods: {
    moment,
    valueFormatter,
    openModal () {
      this.executeOrderModal.show = true
    },
    closeModal () {
      this.executeOrderModal.show = false

      this.executeOrderConfiguration.checkPayment = true
      this.executeOrderConfiguration.processCouponsAndVouchers = true
      this.executeOrderConfiguration.createOrUpdateUsers = true
      this.executeOrderConfiguration.sendEmails = true
      this.executeOrderConfiguration.sendOrderToVoig = true

      this.reexecuteOrderResult = null
      this.reexecutionLoading = false
    },
    async changeToCanceledState () {
      if (confirm('Status wirklich auf Storniert wechseln?')) {
        const result = await this.$store.dispatch(`${this.eParent.key}/setOrderState`, {
          orderId: this.entity.orderId,
          configuration: { state: 'Canceled' }
        })
        if (result.success) {
          this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
        }
      }
    },
    async changeToPartiallyCanceledState () {
      if (confirm('Status wirklich auf Teilstorniert wechseln?')) {
        const result = await this.$store.dispatch(`${this.eParent.key}/setPartiallyCanceledOrderState`, {
          orderId: this.entity.orderId
        })
        if (result.success) {
          this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
        }
      }
    },
    async cancelPosition (item) {
      const result = await this.$store.dispatch(`${this.eParent.key}/changePositionState`, {
        orderId: this.entity.orderId,
        basketItemid: item.basketItemId,
        canceled: true
      })
      if (result.success) {
        this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
      }
    },
    async reactivatePosition (item) {
      const result = await this.$store.dispatch(`${this.eParent.key}/changePositionState`, {
        orderId: this.entity.orderId,
        basketItemid: item.basketItemId,
        canceled: false
      })
      if (result.success) {
        this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
      }
    },
    async changeQuantity (param) {
      const result = await this.$store.dispatch(`${this.eParent.key}/changeQuantityPosition`, {
        orderId: this.entity.orderId,
        basketItemid: param.item.basketItemId,
        configuration: { quantity: param.quantity }
      })
      if (result.success) {
        this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
      }
    },
    async executeOrder () {
      const result = await this.$store.dispatch(`${this.eParent.key}/executeOrder`, {
        orderId: this.entity.orderId,
        configuration: this.executeOrderConfiguration
      })

      this.reexecuteOrderResult = result
      this.reexecutionLoading = false

      if (this.reexecuteOrderResult.success) {
        this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
      }
    },
    addReturns () {
      this.returns.show = true
    },
    hideReturns () {
      this.returns.show = false
    },
    downloadFile (file, filename) {
      const url = window.URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async downloadDeliveryNote () {
      const file = await orderDocumentsService.getDeliveryNoteFileByOrderId(this.eParent.id)
      this.downloadFile(file.file, file.filename)
    },
    async downloadPostZebra () {
      const file = await orderDocumentsService.getPostZebraFileByOrderId(this.eParent.id)
      this.downloadFile(file.file, file.filename)
    },
    async downloadInvoice () {
      const file = await orderDocumentsService.getInvoiceByOrderId(this.eParent.id)
      this.downloadFile(file.file, file.filename)
    }

  }
}
</script>

<style lang="scss">
.order-detail {
  .order-header {
    padding: $spacer;
    background-color: $gray-200;

    dl {
      display: grid;
      grid-gap: $spacer * 0.75;
      grid-auto-flow: column;
      grid-template-columns: auto;
      margin: 0;

      dd {
        margin: 0;
      }
    }
  }

  .order-basket {
    margin-top: $spacer * 2;
  }

  .order-configuration {
    margin-top: $spacer * 2;
  }
}
</style>
