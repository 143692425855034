<template functional>
  <div :id="data.id" :class="['address-line', { 'inline': props.inline }, data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <strong v-if="Object.keys(props.user).length > 0">{{ props.user.firstname }} {{ props.user.lastname }}</strong>
    <template v-if="Object.keys(props.user).length > 0 && Object.keys(props.address).length > 0">, </template>
    <template v-if="Object.keys(props.address).length > 0">{{ props.address.street }} {{ props.address.zip }} {{ props.address.city }}</template>
  </div>
</template>

<script>
export default {
  name: 'AddressLine',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    address: {
      type: Object,
      default: () => ({})
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$addressline-padding-y: $spacer * 0.5 !default;
$addressline-padding-x: 0 !default;
$addressline-border: $border-width solid $border-color !default;

.address-line {
  padding: $addressline-padding-y $addressline-padding-x;
  border-top: $addressline-border;
  border-bottom: $addressline-border;

  + .address-line {
    margin-top: nth($addressline-border, 1) * -1;
  }

  &.inline {
    padding: 0;
    border: 0 none;

    + .address-line {
      margin-top: 0;
    }
  }
}
</style>
