<template>
  <div class="order-configuration">
    <template v-if="summary">
      <h2 class="summary-title">{{ $t('components.order.configuration.summary.title') }}</h2>

      <dl class="summary-list">
        <component
          v-for="(cost, cIndex) in summary"
          :key="cIndex"
          :is="cost.component"
          :text="cost.label"
          :amount="cost.price"
          v-bind="cost.attrs"
        />
      </dl>
    </template>

    <div class="configuration-title">
      <b-row align-v="center">
        <b-col>
          <h2>{{ $t('components.order.configuration.user.title') }}</h2>
        </b-col>

        <b-col cols="auto">
          <b-link :to="{ name: 'Users.Detail', params: { userid: configuration.user.id } }">{{ $t('components.order.configuration.user.detaillink') }}</b-link>
        </b-col>
      </b-row>
    </div>

    <dl v-if="!userIs.loading" class="configuration-list">
      <template v-for="property in user">
        <template v-if="property.visible && property.control.value">
          <dt :key="`title-${property.name}`">{{ property.control.label }}</dt>
          <dd :key="`value-${property.name}`" class="text-wrap-word">{{ valueFormatter(userDefinition, property.name, property.control.value).value }}</dd>
        </template>
      </template>
    </dl>

    <div class="configuration-title">
      <b-row algin-v="center">
        <b-col>
          <h2>{{ $t('components.order.configuration.delivery.title') }}</h2>
        </b-col>

        <b-col cols="auto">
          <b-link :to="{ name: 'Users.Addresses.Detail', params: { userid: configuration.user.id, useraddressid: configuration.invoiceAddress.id } }">{{ $t('components.order.configuration.delivery.detaillink') }}</b-link>
        </b-col>
      </b-row>
    </div>

    <dl class="configuration-list">
      <template v-if="configuration.deliveryType">
        <dt>{{ $t('components.order.configuration.delivery.type.title') }}</dt>
        <dd>{{ $t(`components.order.configuration.delivery.type.${configuration.deliveryType.toLowerCase()}`) }}</dd>
      </template>

      <!-- Lieferung -->
      <template v-if="configuration.deliveryType === 'Delivery'">
        <template v-if="configuration.deliveryFloorType">
          <dt>{{ $t('components.order.configuration.delivery.floor.title') }}</dt>
          <dd>{{ configuration.deliveryFloorType }}</dd>
        </template>

        <template v-if="$root.isBoolean(configuration.deliveryElevatorAvailable)">
          <dt>{{ $t('components.order.configuration.delivery.elevatoravailable.title') }}</dt>
          <dd>{{ $t(`components.order.configuration.delivery.elevatoravailable.${configuration.deliveryElevatorAvailable}`) }}</dd>
        </template>

        <template v-if="configuration.deliveryComments">
          <dt>{{ $t('components.order.configuration.delivery.comments.title') }}</dt>
          <dd>{{ configuration.deliveryComments }}</dd>
        </template>

        <template v-if="$root.isBoolean(configuration.discardOldFurniture)">
          <dt>{{ $t('components.order.configuration.delivery.discardoldfurniture.title') }}</dt>
          <dd>{{ $t(`components.order.configuration.delivery.discardoldfurniture.${configuration.discardOldFurniture}`) }}</dd>
        </template>

        <template v-if="configuration.discardOldFurnitureComment">
          <dt>{{ $t('components.order.configuration.delivery.discardoldfurniturecomment.title') }}</dt>
          <dd>{{ configuration.discardOldFurnitureComment }}</dd>
        </template>

        <template v-if="configuration.deliveryAddress">
          <dt>{{ $t('components.order.configuration.delivery.deliveryaddress.title') }}</dt>
          <dd>
            <address-line :user="configuration.user" :address="configuration.deliveryAddress" inline/>
          </dd>
        </template>
      </template>

      <!-- Abholung -->
      <template v-else-if="configuration.deliveryType === 'Pickup'">
        <template v-if="!$root.isNullOrEmpty(configuration.pickupTimeType) || configuration.pickupDate">
          <dt>{{ $t('components.order.configuration.delivery.pickupdate.title') }}</dt>
          <dd>
            <template v-if="configuration.pickupTimeType === 'SelectPickupTimeLater'">
              {{ $t('components.order.configuration.delivery.pickupdate.selectlater') }}
              <div class="text-muted">{{ $t('components.order.configuration.delivery.pickupdate.selectlaterinfo') }}</div>
            </template>
            <template v-else>{{ $d(new Date(configuration.pickupDate), 'dateDetailed') }}</template>
          </dd>
        </template>

        <template v-if="$root.isBoolean(configuration.partialDelivery)">
          <dt>{{ $t('components.order.configuration.delivery.partialdelivery.title') }}</dt>
          <dd>{{ $t(`components.order.configuration.delivery.partialdelivery.${configuration.partialDelivery}`) }}</dd>
        </template>

        <template>
          <dt>{{ $t('components.order.configuration.delivery.information.title') }}</dt>
          <dd>{{ $t('components.order.configuration.delivery.information.content') }}</dd>
        </template>
      </template>
    </dl>

    <div class="configuration-title">
      <b-row algin-v="center">
        <b-col>
          <h2>{{ $t('components.order.configuration.payment.title') }}</h2>
        </b-col>

        <b-col cols="auto">
          <b-link :to="{ name: 'Users.Addresses.Detail', params: { userid: configuration.user.id, useraddressid: !$root.isNullOrEmpty(configuration.invoiceAddress) ? configuration.invoiceAddress.id : configuration.invoiceAddress.id } }">{{ $t('components.order.configuration.payment.detaillink') }}</b-link>
        </b-col>
      </b-row>
    </div>

    <dl class="configuration-list">
      <template v-if="!$root.isNullOrEmpty(configuration.payrexxPaymentType)">
        <dt>{{ $t('components.order.configuration.payment.type.title') }}</dt>
        <dd>{{ configuration.payrexxPaymentType }}</dd>
      </template>

      <template v-if="!$root.isNullOrEmpty(configuration.payrexxPaymentType) && order.totalPrice > 0">
        <dt>{{ $t('components.order.configuration.payment.creditcard.successlabel') }}</dt>
        <dd><span class="text-success" v-if="order.successPayment">{{ $t('components.order.configuration.payment.creditcard.paymentsuccessful')}}</span><span class="text-danger" v-else>{{ $t('components.order.configuration.payment.creditcard.paymentunsuccessful')}}</span></dd>
      </template>

      <template v-if="!$root.isNullOrEmpty(configuration.coupons)">
        <dt>{{ $t('components.order.configuration.payment.coupons.title') }}</dt>
        <dd>TODO</dd>
      </template>

      <template>
        <dt>{{ $t('components.order.configuration.payment.invoiceaddress.title') }}</dt>
        <dd>
          <address-line :user="configuration.user" :address="!$root.isNullOrEmpty(configuration.invoiceAddress) ? configuration.invoiceAddress : configuration.deliveryAddress" inline/>
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import { valueFormatter, controlMapper } from '@/assets/js/helper/entity'

import SummaryPrice from '@/components/order/SummaryPrice'
import SummarySeparator from '@/components/order/SummarySeparator'
import AddressLine from '@/components/order/AddressLine'

export default {
  name: 'OrderConfiguration',
  components: {
    SummaryPrice,
    SummarySeparator,
    AddressLine
  },
  props: {
    summary: {
      type: Array,
      default: null
    },
    configuration: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    userIs () {
      return this.$store.getters['users/is']
    },
    userDefinition () {
      return this.$store.getters['users/definition']
    },
    user () {
      return controlMapper(this.userDefinition, this.$i18n.locale, [], this.configuration?.user)
    }
  },
  methods: {
    valueFormatter
  },
  created () {
    this.$store.dispatch('users/getDefinition')
  }
}
</script>

<style lang="scss">
$orderconfiguration-title-padding-y: $spacer * 0.65 !default;
$orderconfiguration-title-padding-x: $spacer * 1 !default;
$orderconfiguration-title-bg: $gray-200 !default;
$orderconfiguration-title-font-size: inherit !default;
$orderconfiguration-title-color: inherit !default;

$orderconfiguration-list-gap: $spacer * 3 !default;
$orderconfiguration-list-padding-y: $spacer * 0.5 !default;
$orderconfiguration-list-padding-x: $orderconfiguration-title-padding-x !default;
$orderconfiguration-list-border: $border-width solid $border-color !default;
$orderconfiguration-list-title-font-size: $font-size-sm !default;
$orderconfiguration-list-title-font-weight: inherit !default;
$orderconfiguration-list-title-color: $dark !default;
$orderconfiguration-list-data-font-size: inherit !default;
$orderconfiguration-list-data-font-weight: inherit !default;
$orderconfiguration-list-data-color: inherit !default;
$orderconfiguration-list-data-content-gap: $spacer * 0.3 !default;

.order-configuration {
    .summary-title,
    .configuration-title {
      margin: 0;
      padding: $orderconfiguration-title-padding-y $orderconfiguration-title-padding-x;
      background-color: $orderconfiguration-title-bg;
      font-size: $orderconfiguration-title-font-size;
      color: $orderconfiguration-title-color;

      h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-size: inherit;
      }
    }

    .summary-list {
      margin-bottom: $orderconfiguration-list-gap;
      border-bottom: $orderconfiguration-list-border;

      > div {
        padding: 0 $orderconfiguration-list-padding-x;

        &.summary-separator {
          padding: $orderconfiguration-list-padding-y 0;

          &:first-child { padding-top: 0; }
          &:last-child { padding-top: 0; }

          hr {
            margin: 0;
            border-color: nth($orderconfiguration-list-border, 3);
          }
        }

        &.summary-price {
          &.is-total {
            margin-top: $orderconfiguration-list-padding-y;
            padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
            border-top-color: nth($orderconfiguration-list-border, 3);
          }
        }
      }
    }

    .configuration-list {
      @include make-row(0);
      margin-bottom: $orderconfiguration-list-gap;
      border-top: $orderconfiguration-list-border;

      dt,
      dd {
        @include make-col-ready(0);
        margin: 0;
        padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
        border-bottom: $orderconfiguration-list-border;
      }

      dt {
        @include make-col(3);
        font-size: $orderconfiguration-list-title-font-size;
        font-weight: $orderconfiguration-list-title-font-weight;
        color: $orderconfiguration-list-title-color;
      }

      dd {
        @include make-col(9);
        font-size: $orderconfiguration-list-data-font-size;
        font-weight: $orderconfiguration-list-data-font-weight;
        color: $orderconfiguration-list-data-color;

        > * {
          margin-bottom: $orderconfiguration-list-data-content-gap;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
