import http from '@/$plugins/http'
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

const downloadFile = async (url) => {
  const result = await http({
    method: 'get',
    url: url,
    responseType: 'blob'
  })

  if (result) {
    console.log(result)
    const filename = result.headers['content-disposition']?.split(';')?.find(n => n.includes('filename='))?.replace('filename=', '').trim()
    return { file: result.data, filename: filename }
  }
  console.log(result.data.errorMessage)
  return null
}

export default {
  async getInvoiceByOrderId (orderId) {
    return await downloadFile(`/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/orderinvoice/${orderId}`)
  },
  async getPostZebraFileByOrderId (orderId) {
    return await downloadFile(`/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/orderdocument/zebra/${orderId}`)
  },
  async getDeliveryNoteFileByOrderId (orderId) {
    return await downloadFile(`/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/orderdocument/deliverynote/${orderId}`)
  }
}
