<template>
  <div class="basket-item">
    <div class="area-separator" />

    <div class="area-image">
      <img v-if="item.item.image" :src="item.item.image" :alt="item.item.name" />
      <aspect-ratio v-else ratio="4x3" class="is-fallback-image">
        <b-icon icon="images" />
      </aspect-ratio>
    </div>

    <div class="area-description">
      <p class="mb-0"> {{ item.item.name }}<br /><span class="item-name" v-if="item.itemType == 'Product'">{{ item.item.pharmaCode }}</span></p>
      <b-badge v-if="errorMessage" variant="danger">{{ errorMessage }}</b-badge>
    </div>

    <div class="area-quantity">
      <template v-if="canEditQuantity">
        <b-input v-model="itemQuantity" type="number" @change="changeQuantity"></b-input>
      </template>
      <span v-else>{{ itemQuantity }}</span>
    </div>

    <div class="area-price">
      <item-price :productId="item.item.id" :priceinfo="item.item.priceInfo" :configuration="item.configuration" alginH="end" hideVatInfo inherit />
    </div>

    <div class="area-totalprice">
      <item-price :productId="item.item.id" :priceinfo="item.item.priceInfo" :configuration="item.configuration" :quantity="item.quantityOverride ? item.quantityOverride : item.quantity" alginH="end" hideVatInfo inherit />
    </div>
    <div class="area-services" v-if="canCancel">
      <b-button size="sm" variant="danger" @click="cancelPosition()"><b-icon icon="abort" /> Position stornieren</b-button>
    </div>
    <div class="area-services" v-if="canReactivate">
      <b-button size="sm" variant="success" @click="reactivatePosition()"><b-icon icon="b-arrow-clockwise" /> Position reaktivieren</b-button>
    </div>
    <slot></slot>
    <div class="area-separator" />
  </div>
</template>

<script>
import AspectRatio from '@/components/AspectRatio'
import ItemPrice from '@/components/order/ItemPrice'

export default {
  name: 'BasketItem',
  components: {
    ItemPrice,
    AspectRatio
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    orderState: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      itemQuantity: this.item.quantityOverride ?? this.item.quantity
    }
  },
  computed: {
    canEditQuantity () {
      return this.orderState === 'PartialDelivery'
    },
    canCancel () {
      return this.orderState === 'OrderFailed' && !this.item.isCanceled
    },
    canReactivate () {
      return this.orderState === 'OrderFailed' && this.item.isCanceled
    }
  },
  methods: {
    cancelPosition () {
      this.$emit('cancelPosition', this.item)
    },
    reactivatePosition () {
      this.$emit('reactivatePosition', this.item)
    },
    changeQuantity () {
      this.$emit('changeQuantity', { item: this.item, quantity: this.itemQuantity })
    }
  }
}
</script>

<style lang="scss">
$basketitem-separator-gap-y: $spacer * 0.4 !default;
$basketitem-separator-border: $border-width solid $border-color !default;

$basketitem-image-width: 100px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $light !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

.order-basket {
  .basket-items {
    .basket-item {
      display: contents;

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;
      }

      .area-image {

        img,
        .is-fallback-image {
          display: block;
          margin: 0;
          width: $basketitem-image-width;
          height: $basketitem-image-width;
          object-fit: contain;
          max-width: none;

          .bi {
            display: block;
            margin: auto;
            width: $basketitem-img-fallback-size;
            height: $basketitem-img-fallback-size;
            color: $basketitem-img-fallback-color;
          }
        }
      }

      .area-description {
        .item-name {
          font-weight: $basketitem-description-name-font-weight;
          text-transform: $basketitem-description-name-text-transform;
        }
      }

      .area-configuration {
        .item-configuration {
          display: flex;
          flex-wrap: wrap;
          margin: ($basketitem-configuration-gap-y * -0.5) ($basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }
      }

      .area-price,
      .area-totalprice {
        white-space: nowrap;
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      +.basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
</style>
