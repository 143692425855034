import http from '@/$plugins/http'
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

export default {
  async getOrderReturnsByOrderId (orderId) {
    const result = await http({
      method: 'get',
      url: `/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/orderreturns/byorderid/${orderId}`
    })

    if (result.data.success) {
      return result.data.result
    }
    console.log(result.data.errorMessage)
    return null
  },
  async addOrderReturnsByOrderId (data) {
    const result = await http({
      method: 'post',
      data: data,
      url: `/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/orderreturns/addreturn`
    })

    if (result.data.success) {
      return result.data.result
    }
    console.log(result.data.errorMessage)
    return null
  }
}
