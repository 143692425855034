var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['itemprice', { 'inherit': _vm.inherit, 'is-sum': _vm.quantity > 1 }]},[_c('b-row',{attrs:{"no-gutters":"","align-h":_vm.alignH}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('i18n-n',{class:['price-active', { 'is-promotional': _vm.priceinfo.isPromotionalPrice, 'hide-fraction': _vm.activePrice.hideFraction }],attrs:{"value":_vm.activePrice.number * _vm.quantity,"format":{ key: 'currency', currency: _vm.currency }},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var currency = ref.currency;
return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(currency))])]}},{key:"integer",fn:function(ref){
var integer = ref.integer;
return [_c('span',{staticClass:"integer"},[_vm._v(_vm._s(integer))])]}},{key:"group",fn:function(ref){
var group = ref.group;
return [_c('span',{staticClass:"group"},[_vm._v(_vm._s(group))])]}},{key:"decimal",fn:function(ref){
var decimal = ref.decimal;
return [_c('span',{staticClass:"decimal"},[_vm._v(_vm._s(decimal))])]}},{key:"fraction",fn:function(ref){
var fraction = ref.fraction;
return [_c('span',{staticClass:"fraction"},[_vm._v(_vm._s(fraction))])]}}])})],1),_c('b-col',{attrs:{"cols":"auto"}},[(_vm.priceOriginal.isValid)?_c('i18n-n',{class:['price-original', { 'hide-fraction': _vm.priceOriginal.hideFraction }],attrs:{"value":_vm.priceOriginal.number * _vm.quantity,"format":{ key: 'currency', currency: _vm.currency }},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var currency = ref.currency;
return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(currency))])]}},{key:"integer",fn:function(ref){
var integer = ref.integer;
return [_c('span',{staticClass:"integer"},[_vm._v(_vm._s(integer))])]}},{key:"group",fn:function(ref){
var group = ref.group;
return [_c('span',{staticClass:"group"},[_vm._v(_vm._s(group))])]}},{key:"decimal",fn:function(ref){
var decimal = ref.decimal;
return [_c('span',{staticClass:"decimal"},[_vm._v(_vm._s(decimal))])]}},{key:"fraction",fn:function(ref){
var fraction = ref.fraction;
return [_c('span',{staticClass:"fraction"},[_vm._v(_vm._s(fraction))])]}}],null,false,1066444551)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }